import cn from 'classnames'
import styles from './styles.module.scss'
import { FC, useMemo, useRef, useState } from 'react'
import { ITariff } from '@services/types'
import { useOutside } from '@hooks/useOutside'

interface Props {
  select: number | string
  selects: ITariff[]
  className?: string
  onChange: (value: number) => void
}

const Select: FC<Props> = ({ select, onChange, className, selects }) => {
  const onChangeHandler = (value: number) => {
    onChange(value)
  }

  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)
  useOutside(ref, () => setOpen(false))

  const selectItem = useMemo(() => {
    return selects.find((it) => it.id === select)
  }, [select, selects])

  const openHandler = () => {
    setOpen(!isOpen)
  }

  const classNames = cn(styles.select, className, {
    [styles.show]: isOpen,
  })

  return (
    <div className={classNames} onClick={openHandler}>
      <p className={cn(styles.selectText, styles.show)}>{selectItem?.name}</p>

      <span className={styles.arrow}></span>

      <div
        className={cn(styles.list, {
          [styles.show]: isOpen,
        })}
      >
        {selects.map((item) => {
          return (
            <p
              key={item.id}
              onClick={() => onChangeHandler(item.id)}
              className={styles.selectText}
            >
              {item.name}
            </p>
          )
        })}
      </div>
    </div>
  )
}

export default Select
