import { useContext, createContext, useMemo, FC, ReactElement } from 'react'

type IContext = {}

const AppContext = createContext<IContext>({} as IContext)

type AppProviderType = {
  children: ReactElement
}

export const AppProvider: FC<AppProviderType> = ({ children }) => {
  const value = useMemo(() => {
    return {}
  }, [])

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useAppContext = () => {
  return useContext(AppContext)
}
