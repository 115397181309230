import { mainReducer } from './redusers/main/main'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { userApi } from '@services/user'

const rootReducer = combineReducers({
  main: mainReducer,
  [userApi.reducerPath]: userApi.reducer,
})

export const store = configureStore({
  reducer: rootReducer,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(userApi.middleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
