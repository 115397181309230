import cn from 'classnames'
import styles from './styles.module.scss'
import { FC, ReactNode, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLButtonElement> {
  children: ReactNode
}

const Button: FC<Props> = ({ children, className, ...rest }) => {
  const classNames = cn(styles.button, className)
  return (
    <button className={classNames} {...rest}>
      {children}
    </button>
  )
}

export default Button
