import cn from 'classnames'
import { FC, HTMLAttributes } from 'react'
import React from 'react'
import styles from './styles.module.scss'

interface Props extends HTMLAttributes<HTMLDivElement> {
  src?: string
}

const Loader: FC<Props> = ({ children, className, src, ...rest }) => {
  const classNames = cn(styles.loaderWrapper, className)
  return (
    <div className={classNames}>
      <div className={styles.loader} {...rest}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}

export default Loader
