import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { BASE_URL } from './constans'
import { ITariff } from './types'
import { localStorageKeys } from './localStorageKeys'

type Link = {
  tariff_id: number
  is_partner?: boolean
  is_subscribe: boolean
  phone?: string
  sbp?: number | string
  for_two?: 0 | 1
}

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: async (headers, { endpoint }) => {
      const localToken =
        localStorage.getItem(localStorageKeys.login) &&
        JSON.parse(localStorage.getItem(localStorageKeys.login) as string)
      const user = localToken

      // console.log('user', user)

      if (user && endpoint !== 'refresh') {
        headers.set('Authorization', `Bearer ${user.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['tariff', 'paymentLink'],
  endpoints: (builder) => ({
    getTariffsList: builder.query<{ tariffs: ITariff[] }, string>({
      query(is_subscribe) {
        return {
          url: 'api/tariffs',
          method: 'GET',
          params: {
            is_subscribe,
          },
        }
      },
      providesTags: ['tariff'],
    }),

    getPaymentLink: builder.query<any, Link>({
      query({ tariff_id, is_partner, is_subscribe, phone, for_two }) {
        return {
          url: 'api/payment',
          params: {
            tariff_id: tariff_id,
            is_partner,
            is_subscribe,
            phone,
            sbp: 0,
            for_two,
          },
          method: 'GET',
        }
      },
      providesTags: ['paymentLink'],
    }),
  }),
})

export const { useGetTariffsListQuery, useLazyGetPaymentLinkQuery } = userApi
