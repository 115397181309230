export const clearPhoneMask = (phone: string) => phone.replace(/\D/g, '')

export const dateFormat = (date: Date | number | string) => {
  const newDate = new Date(date)
  return new Intl.DateTimeFormat('ru-RU').format(newDate)
}

export const priceFormat = (amount: number) => {
  return new Intl.NumberFormat('ru-RU', {
    style: 'currency',
    currency: 'RUB',
  }).format(amount)
}
