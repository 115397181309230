import cn from 'classnames'
import styles from './styles.module.scss'
import { FC, InputHTMLAttributes } from 'react'
import InputMask from 'react-input-mask'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  classNameWrapper?: string
  label?: string
}

const InputPhone: FC<Props> = ({
  children,
  className,
  classNameWrapper,
  label,
  disabled,
  ...rest
}) => {
  const classNamesWrapper = cn(styles.inputWrapper, classNameWrapper)
  const classNames = cn(styles.input, className)

  return (
    <div className={classNamesWrapper} {...rest}>
      {label && <p className={styles.label}>{label}</p>}

      <div className={styles.fildWrapper}>
        <InputMask
          mask="+7 (999) 999 99 99"
          className={classNames}
          disabled={disabled}
          {...rest}
        />
      </div>
    </div>
  )
}

export default InputPhone
