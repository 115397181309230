import { FC, ReactNode } from 'react'
import styles from './styles.module.scss'
import Header from '@organisms/Header'
import cn from 'classnames'

type layout = {
  children: ReactNode
  isMain?: boolean
}

const Layout: FC<layout> = ({ children, isMain }) => {
  return (
    <div
      className={cn(styles.app, {
        [styles.isMain]: isMain,
      })}
    >
      <div className={styles.wrapper}>
        <Header isMain={isMain} />
        <main className={styles.main}>{children}</main>
      </div>
    </div>
  )
}

export default Layout
