import { PayloadAction } from '@reduxjs/toolkit'
import { IStore } from '@store/redusers/main/types'

export type MainActions = {
  toggleMenu: (state: IStore, payload: PayloadAction<boolean>) => void
}

export const mainActions: MainActions = {
  toggleMenu: (state, { payload }) => {
    state.openMenu = payload
  },
}
