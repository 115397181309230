import cn from 'classnames'
import styles from './styles.module.scss'
import { FC, ReactNode, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

const Card: FC<Props> = ({ children, className, ...rest }) => {
  const classNames = cn(styles.card, className)
  return (
    <div className={classNames} {...rest}>
      {children}
    </div>
  )
}

export default Card
