import Card from '@organisms/Card'
import styles from './styles.module.scss'
import Button from '@atoms/Button'
import InputPhone from '@atoms/InputPhone'
import { useInput } from '@hooks/useInput'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { clearPhoneMask } from '@hooks/helpers'
import Loader from '@atoms/Loader'
import cn from 'classnames'
import {
  useGetTariffsListQuery,
  useLazyGetPaymentLinkQuery,
} from '@services/user'
import Select from '@atoms/Select'
import { useInitialData } from '@hooks/useInitialData'

const AuthTemplate = () => {
  const { tariff_id, phone: defaultPhone } = useInitialData()

  const {
    bind: bindPhone,
    setValue,
    value: phone,
  } = useInput(defaultPhone || '')
  const [tariffid, setTariffid] = useState(tariff_id || 1)
  const [error, setError] = useState(null)
  const { data: subscribe, isLoading: isLoadingSub } =
    useGetTariffsListQuery('1')
  const { data: psychologist, isLoading: isLoadingPsy } =
    useGetTariffsListQuery('0')
  const [getLink, { data: link, isLoading: isLoadingLink }] =
    useLazyGetPaymentLinkQuery()

  const isLoading = useMemo(() => {
    return isLoadingPsy || isLoadingSub || isLoadingLink
  }, [isLoadingPsy, isLoadingSub, isLoadingLink])

  const tariffs = useMemo(() => {
    return subscribe && psychologist
      ? [...new Set([...subscribe.tariffs, ...psychologist.tariffs])]
      : []
  }, [subscribe, psychologist])

  const submit = async () => {
    const data = {
      phone: clearPhoneMask(phone),
      tariff_id: tariffid,
      is_subscribe: true,
    }
    setError(null);
    getLink(data)
  }

  const onSelect = useCallback((value: number) => {
    setTariffid(value)
  }, [])

  useEffect(() => {
    if (!link) {
      return
    }
    if (typeof link === 'string') {
      const win: Window = window
      win.location = link
    } else if (!link.success){
      setError(link.error)
    }
  }, [link])

  useEffect(() => {
    setValue(defaultPhone)
  }, [defaultPhone, setValue])

  return (
    <div className={styles.content}>
      <Card className={styles.form}>
        {isLoading && <Loader className={styles.loader} />}

        <h1 className={styles.title}>Оплата</h1>

        <div className={styles.filds}>
          <InputPhone
            required
            {...bindPhone}
            label="Номер телефона"
            className={styles.phone}
          />
          <Select onChange={onSelect} select={tariffid} selects={tariffs} />
        </div>

        <p
          className={cn(styles.error, {
            [styles.showError]: !!error,
          })}
        >
          {error || 'Ошибка'}
        </p>
        <Button className={styles.submit} onClick={submit}>
          Оплатить
        </Button>
      </Card>
    </div>
  )
}

export default AuthTemplate
