import { localStorageKeys } from '@services/localStorageKeys'
import { useEffect, useState } from 'react'

type InitialData = {
  phone: string
  tariff_id: null | number
  is_subscribe: null | string
  is_partner: null | string
  is_spb: null | string
  for_two: null | string
}

export const useInitialData = () => {
  const [initialData, setInitialData] = useState<InitialData>({
    phone: '',
    tariff_id: null,
    is_subscribe: null,
    is_partner: null,
    is_spb: null,
    for_two: null,
  })

  useEffect(() => {
    const prevPhone =
      localStorage.getItem(localStorageKeys.phone) &&
      JSON.parse(localStorage.getItem(localStorageKeys.phone) as string)
    const prevForTwo =
      localStorage.getItem(localStorageKeys.for_two) &&
      JSON.parse(localStorage.getItem(localStorageKeys.for_two) as string)
    const prevIsSpb =
      localStorage.getItem(localStorageKeys.is_spb) &&
      JSON.parse(localStorage.getItem(localStorageKeys.is_spb) as string)

    const prevIsPartner =
      localStorage.getItem(localStorageKeys.is_partner) &&
      JSON.parse(localStorage.getItem(localStorageKeys.is_partner) as string)

    const prevIsSubscribe =
      localStorage.getItem(localStorageKeys.is_subscribe) &&
      JSON.parse(localStorage.getItem(localStorageKeys.is_subscribe) as string)

    const prevTarrifId =
      localStorage.getItem(localStorageKeys.tariff_id) &&
      JSON.parse(localStorage.getItem(localStorageKeys.tariff_id) as string)

    const queryString = window.location.href
    const url = new URL(queryString)
    const tariff_id =
      url.searchParams.get(localStorageKeys.tariff_id) || prevTarrifId
    const is_subscribe =
      url.searchParams.get(localStorageKeys.is_subscribe) || prevIsSubscribe
    const is_partner =
      url.searchParams.get(localStorageKeys.is_partner) || prevIsPartner
    const is_spb = url.searchParams.get(localStorageKeys.is_spb) || prevIsSpb
    const for_two = url.searchParams.get(localStorageKeys.for_two) || prevForTwo
    const phone = url.searchParams.get(localStorageKeys.phone) || prevPhone

    if (tariff_id) {
      setInitialData((prev) => ({ ...prev, tariff_id: +tariff_id }))
      localStorage.setItem(localStorageKeys.tariff_id, tariff_id)
    }

    if (is_subscribe) {
      setInitialData((prev) => ({ ...prev, is_subscribe }))
      localStorage.setItem(localStorageKeys.is_subscribe, is_subscribe)
    }

    if (is_partner) {
      setInitialData((prev) => ({ ...prev, is_partner }))
      localStorage.setItem(localStorageKeys.is_partner, is_partner)
    }

    if (is_spb) {
      setInitialData((prev) => ({ ...prev, is_spb }))
      localStorage.setItem(localStorageKeys.is_spb, is_spb)
    }
    if (for_two) {
      setInitialData((prev) => ({ ...prev, for_two }))
      localStorage.setItem(localStorageKeys.for_two, for_two)
    }
    if (phone) {
      setInitialData((prev) => ({ ...prev, phone }))
      localStorage.setItem(localStorageKeys.phone, phone)
    }
  }, [])

  return initialData
}
