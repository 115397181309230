import styles from './styles.module.scss'
import { FC } from 'react'
import Logo from '@atoms/Logo'
import cn from 'classnames'

type Props = {
  isMain?: boolean
}

const Header: FC<Props> = ({ isMain }) => (
  <header
    className={cn(styles.header, {
      [styles.isMain]: isMain,
    })}
  >
    <Logo className={styles.logo} />
  </header>
)

export default Header
