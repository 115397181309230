import cn from 'classnames'
import styles from './styles.module.scss'
import { FC, HTMLAttributes } from 'react'

interface Props extends HTMLAttributes<HTMLDivElement> {}

const Logo: FC<Props> = ({ children, className, ...rest }) => {
  const classNames = cn(styles.logo, className)
  return <div className={classNames} {...rest} />
}

export default Logo
